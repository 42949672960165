import React, { useEffect, useReducer } from 'react';
import { AnyAction } from 'redux';
import { Tree, TreeNodeInfo } from '@blueprintjs/core';
import { cloneDeep, compact, get, map, startCase } from 'lodash';

import { SetupUITemplate, SetupUITemplateContainerItem, SetupUITemplateItem, SetupUITemplateItemType } from 'types';

type NodePath = number[];

interface SetIsExpandedTreeAction extends AnyAction {
  type: 'SET_IS_EXPANDED';
  payload: {
    path: NodePath;
    isExpanded: boolean;
  }
}

interface UpdateNodesTreeAction extends AnyAction {
  type: 'UPDATE_NODES';
  payload: {
    template: SetupUITemplate;
  }
}

const matchSetIsExpandedAction = (action: AnyAction): action is SetIsExpandedTreeAction => {
  return action.type === 'SET_IS_EXPANDED';
};

const matchUpdateNodesAction = (action: AnyAction): action is UpdateNodesTreeAction => {
  return action.type === 'UPDATE_NODES';
};

export type TemplateItemNodeInfo = TreeNodeInfo<SetupUITemplateContainerItem>;

let id = 0;
const getTreeNodeInfo = (item: SetupUITemplateItem): TemplateItemNodeInfo | null => {
  if (item.type === SetupUITemplateItemType.FIELD || item.type === SetupUITemplateItemType.GRID) return null;

  const childNodes = compact(item.items.map(getTreeNodeInfo));
  const info: TemplateItemNodeInfo = {
    id: id++,
    childNodes,
    hasCaret: childNodes.length > 0,
    isExpanded: true,
    label: item.label ?? startCase(item.name),
    nodeData: item,
  };
  return info;
};

const forNodeAtPath = (nodes: TemplateItemNodeInfo[], path: NodePath, callback: (node: TemplateItemNodeInfo) => void) => {
  callback(Tree.nodeFromPath(path, nodes));
};

const reducer = (state: TemplateItemNodeInfo[], action: AnyAction) => {
  if (matchSetIsExpandedAction(action)) {
    const newState = cloneDeep(state);
    forNodeAtPath(newState, action.payload.path, node => {
      node.isExpanded = action.payload.isExpanded;
    });
    return newState;
  } else if (matchUpdateNodesAction(action)) {
    id = 0;
    return compact(map(get(action, 'payload.template.items', []) as SetupUITemplateItem[], (item) => getTreeNodeInfo(item)));
  }
  return state;
};

interface Props {
  template: SetupUITemplate;
  onClick?: (path: TemplateItemNodeInfo[]) => void;
}

export default (props: Props) => {
  const [nodes, dispatch] = useReducer(reducer, compact(props.template.items.map(getTreeNodeInfo)));

  useEffect(() => {
    dispatch({
      type: 'UPDATE_NODES',
      payload: {
        template: props.template,
      },
    });
  }, [props.template]);

  const handleNodeClick = (_node: TemplateItemNodeInfo, nodePath: NodePath) => {
    const nodeInfoPath = nodePath.reduce((acc, path, currIndex, allNodes) => {
      acc.push(Tree.nodeFromPath(allNodes.slice(0, currIndex + 1), nodes));
      return acc;
    }, [] as TemplateItemNodeInfo[]);
    props.onClick?.(nodeInfoPath);
  };

  const handleNodeCollapse = (_node: TemplateItemNodeInfo, nodePath: NodePath) => {
    dispatch({
      payload: { path: nodePath, isExpanded: false },
      type: 'SET_IS_EXPANDED',
    });
  };

  const handleNodeExpand = (_node: TemplateItemNodeInfo, nodePath: NodePath) => {
    dispatch({
      payload: { path: nodePath, isExpanded: true },
      type: 'SET_IS_EXPANDED',
    });
  };

  if (nodes.length === 0) return null;
  return (
    <Tree
      contents={nodes}
      onNodeClick={handleNodeClick}
      onNodeCollapse={handleNodeCollapse}
      onNodeExpand={handleNodeExpand}
    />
  );
};
