import { useEffect, useState, useCallback } from 'react';
import {
  useParams,
  useBlocker,      // eslint-disable-line camelcase
  BlockerFunction, // eslint-disable-line camelcase
} from 'react-router-dom';
import {
  Button,
  FormGroup,
  Intent,
  Checkbox,
  Alignment,
  EditableText,
  NonIdealState,
  Tree,
  TreeNodeInfo,
} from '@blueprintjs/core';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { format } from 'date-fns';
import { isEmpty, get, map, toNumber, toString, cloneDeep, set } from 'lodash';
import classNames from 'classnames';
import SVG from 'react-inlinesvg';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';

import {
  Environment,
  HistoricalWeather,
  useEnvironmentByIdQuery,
  useGetWeatherLazyQuery,
  useUpdateEnvironmentMutation,
  UpdateEnvironmentInput,
  useLapWindDataQuery,
  useGetTrackImageLazyQuery,
} from 'graphql/generated/graphql';
import RHFNumericInput from 'components/RHFInputs/NumericInput';
import RHFSelect from 'components/RHFInputs/Select';
import Accordion from 'components/Accordion';
import AppToaster from 'helpers/toaster';
import { addWarningListener, removeWarningListener } from 'helpers/browserCloseWarning';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import styles from './index.module.css';

type EnvironmentForm = {
  name?: string;
  track?: string;
  elevation?: number;
  latitude?: number;
  longitude?: number;
  wind_scalar?: number;
  wind_speed?: number | string | undefined;
  wind_direction?: number | string | undefined;
  temperature?: number | string | undefined;
  humidity?: number | string | undefined;
  pressure?: number | string | undefined;
  use_lap_wind_data?: boolean | null | undefined;
  lap_wind_data?: number;
  historical_weather?: HistoricalWeather;
}

type LapWindDataItem = {
  label: string
  value: number
}

const nodes: TreeNodeInfo[] = [
  {
    id: 0,
    hasCaret: false,
    label: 'Weather',
  },
  {
    id: 1,
    hasCaret: false,
    label: 'Track',
  },
];

export default () => {
  const params = useParams();
  const environmentId = Number(params.environmentId);

  const [environment, setEnvironment] = useState<Environment>();
  const [lapWindDataItems, setLapWindDataItems] = useState<LapWindDataItem[]>([]);
  const [dateValue, onDateValueChange] = useState<Date>(new Date());
  const [trackSvgImageUrl, setTrackImageSvgImageUrl] = useState('');

  useDocumentTitle(
    environment && environment.name ? `Apex Setup - ${environment.name}` : 'Apex Setup'
  );

  const form = useForm<EnvironmentForm>({
    defaultValues: {
      name: '',
      track: '',
      elevation: 0,
      latitude: 0,
      longitude: 0,
      wind_scalar: 0,
      wind_speed: '',
      wind_direction: '',
      temperature: '',
      humidity: '',
      pressure: '',
      use_lap_wind_data: false,
      lap_wind_data: undefined,
      historical_weather: undefined,
    },
  });
  const { control, setValue, getValues, formState: { errors, isDirty }, watch } = form;

  const { loading: environmentLoading } = useEnvironmentByIdQuery({
    variables: { id: environmentId },
    skip: !environmentId,
    onCompleted: data => {
      if (data.environment) {
        setEnvironment(data.environment);
        form.reset({
          name: data.environment.name,
          track: data.environment.track,
          elevation: data.environment.elevation,
          wind_scalar: data.environment.wind_scalar,
          wind_speed: toString(data.environment.wind_speed),
          wind_direction: toString(data.environment.wind_direction),
          temperature: toString(data.environment.temperature),
          humidity: toString(data.environment.humidity),
          pressure: toString(data.environment.pressure),
          latitude: data.environment.latitude,
          longitude: data.environment.longitude,
          use_lap_wind_data: data.environment.use_lap_wind_data,
          lap_wind_data: data.environment.lap_wind_data?.id || undefined,
          historical_weather: data.environment.historical_weather,
        });
      }
    },
    fetchPolicy: 'no-cache',
  });

  const temperature = watch('temperature');
  const pressure = watch('pressure');
  const humidity = watch('humidity');
  const windSpeed = watch('wind_speed');
  const windDirection = watch('wind_direction');
  const useLapWindData = watch('use_lap_wind_data');
  const track = watch('track');
  const elevation = watch('elevation');
  const windScalar = watch('wind_scalar');

  const [getWeather] = useGetWeatherLazyQuery();
  const [updateEnvironment] = useUpdateEnvironmentMutation();
  const { data: lapWindData } = useLapWindDataQuery();
  const [getTrackImage] = useGetTrackImageLazyQuery();

  useEffect(() => {
    if (track) {
      getTrackImage({
        variables: { short_name: track },
        onCompleted: data => {
          if (data?.trackImage?.svg_url) {
            setTrackImageSvgImageUrl(data.trackImage.svg_url);
          }
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [track]);

  useEffect(() => {
    if (lapWindData?.lapWindDatas) {
      const formattedLapWindDataItems = map(lapWindData.lapWindDatas, (lwd) => ({
        value: lwd.id,
        label: lwd.desc,
      }));
      setLapWindDataItems(formattedLapWindDataItems);
    }
  }, [lapWindData]);

  useEffect(() => {
    if (isDirty) {
      addWarningListener();
    } else {
      removeWarningListener();
    }
  }, [isDirty]);

  // Removes the listener when deconstructing this component
  useEffect(() => removeWarningListener, []);

  // eslint-disable-next-line
  const blockerFunc: BlockerFunction = () => {
    if (isDirty) {
      // eslint-disable-next-line
      return !window.confirm(
        'There are unsaved changes. Navigate away from this view?'
      );
    }
    return false;
  };
  useBlocker(blockerFunc);

  const handleNodeClick = useCallback(
    (node: TreeNodeInfo) => {
      const sectionElement = document.getElementById(`section-${node.id}`);

      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' });
      }
    },
    [],
  );

  const onEnvironmentMetaChange = (target: string, value: string | number) => {
    if (!environment) return;

    const newEnvironment = cloneDeep(environment);
    set(newEnvironment, target, value);
    setEnvironment(newEnvironment);
  };

  const handleFetchWeather = () => {
    const requestDate = new Date(dateValue);
    const offsetMins = requestDate.getTimezoneOffset();
    const latitude = getValues('latitude');
    const longitude = getValues('longitude');
    const adjRequestDate = new Date(requestDate.getTime() - (offsetMins * 60 * 1000));
    let isoReqestDate = adjRequestDate.toISOString();
    isoReqestDate = isoReqestDate.split('.')[0];
    if (latitude && longitude && requestDate) {
      getWeather({
        variables: {
          latitude,
          longitude,
          date: isoReqestDate,
        },
        onCompleted: data => {
          if (data?.weather) {
            setValue('historical_weather', {
              ...data?.weather,
              wind_scalar: getValues('wind_scalar'),
            }, { shouldDirty: true });

            if (data?.weather?.temperature) {
              setValue('temperature', data.weather.temperature, { shouldDirty: true });
            }
            if (data?.weather?.humidity) {
              setValue('humidity', data.weather.humidity, { shouldDirty: true });
            }
            if (data?.weather?.pressure) {
              setValue('pressure', data.weather.pressure, { shouldDirty: true });
            }
            if (data?.weather?.windSpeed) {
              const windScalar = getValues('wind_scalar');
              if (windScalar && !Number.isNaN(windScalar)) {
                setValue('wind_speed', (windScalar * data.weather.windSpeed), { shouldDirty: true });
              }
            }
            if (data?.weather?.windDirection) {
              setValue('wind_direction', data.weather.windDirection, { shouldDirty: true });
            }
          }
          AppToaster.show({
            intent: Intent.SUCCESS,
            message: 'Historical Weather Fetched',
          });
        },
        onError: e => {
          AppToaster.show({
            intent: Intent.DANGER,
            message: `Error fetching historical weather: ${e.message}`,
          });
        },
      });
    }
  };

  const onSubmit = (input: EnvironmentForm) => {
    if (environment) {
      const updatedEnvironmentInput: UpdateEnvironmentInput = {
        id: environment.id,
        name: input.name || '',
        temperature: toNumber(input.temperature) || 0,
        pressure: toNumber(input.pressure) || 0,
        humidity: toNumber(input.humidity) || 0,
        wind_speed: toNumber(input.wind_speed) || 0,
        wind_direction: toNumber(input.wind_direction) || 0,
        wind_scalar: input.wind_scalar || 0,
        historical_weather: input.historical_weather,
        use_lap_wind_data: input.use_lap_wind_data,
        lap_wind_data_id: input.lap_wind_data || undefined,
      };
      updateEnvironment({
        variables: {
          input: {
            ...updatedEnvironmentInput,
          },
        },
        onCompleted: () => {
          AppToaster.show({
            intent: Intent.SUCCESS,
            message: 'Environment successfully updated',
          });

          form.reset({ ...input });
        },
        onError: e => {
          AppToaster.show({
            intent: Intent.DANGER,
            message: `Error updating Environment: ${e.message}`,
          });
        },
      });
    }
  };

  const renderTrackImage = () => {
    if (!trackSvgImageUrl) {
      return (
        <NonIdealState
          icon="clean"
          title="No Track Image"
          description="Track image is not available for this environment (yet!)"
        />
      );
    }

    const windArrow = { transform: 'rotate(0deg)' };

    if (windDirection || toNumber(windDirection) === 0) {
      const windArrowRotation = toNumber(windDirection) + 90;
      windArrow.transform = `rotate(${windArrowRotation}deg)`;
    }

    return (
      <div className={styles.flexSection}>
        <div className={styles.trackImageContainer}>
          <SVG src={trackSvgImageUrl} preloader={<div>Loading</div>} className={styles.trackImage} />
          <div className={styles.arrowImageContainer}>
            <svg
              id="wind-arrow"
              className={styles.arrowImage}
              width="120"
              height="120"
              viewBox="0 0 120 120"
              style={windArrow}
            >
              <defs>
                <marker
                  id="markerArrow"
                  markerWidth="12"
                  markerHeight="12"
                  refX="2"
                  refY="7"
                  orient="auto"
                >
                  <path d="M2,2 L2,12 L8,7 L2,2" />
                </marker>
              </defs>
              <line
                className={styles.arrowImageLine}
                x1="8"
                y1="60"
                x2="100"
                y2="60"
                stroke="#000"
                strokeWidth="3"
                markerEnd="url(#markerArrow)"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  };

  if (environmentLoading) return null;

  return (
    <FormProvider {...form}>
      <form className={styles.mainForm} onSubmit={form.handleSubmit(onSubmit)}>
        <div className={styles.titleBar}>
          <EditableText
            className={styles.titleValue}
            value={environment?.name}
            onChange={value => {
              onEnvironmentMetaChange('name', value);
              setValue('name', value);
            }}
            placeholder="Environment"
          />
        </div>
        <div className={styles.mainContainer}>
          <div className={styles.nav}>
            <Button
              className={styles.saveButton}
              intent={Intent.PRIMARY}
              type="submit"
              text="Save"
              fill
              disabled={!isDirty}
            />
            <div className={styles.sideNavContainer}>
              <Tree
                contents={nodes}
                onNodeClick={handleNodeClick}
              />
            </div>
          </div>
          <div className={styles.envContainer}>
            <Accordion
              className={styles.itemContainer}
              id="section-0"
              initialOpen
              key="weather"
              title="Weather"
              buttonProps={{
                className: styles.accordionHeader,
              }}
            >
              <div className={styles.sectionContainer}>
                <div className={styles.inputRow}>
                  <FormGroup
                    className={classNames(styles.formGroup, styles.flex)}
                    contentClassName={styles.envInput}
                    label="Wind Scalar"
                    inline
                  >
                    <RHFNumericInput
                      controllerProps={{
                        control,
                        name: 'wind_scalar',
                      }}
                      inputProps={{
                        fill: true,
                        className: styles.inline,
                        value: toString(windScalar),
                      }}
                    />
                  </FormGroup>
                </div>
                <div className={styles.inputRow}>
                  <DateTimePicker
                    onChange={onDateValueChange}
                    value={dateValue}
                    disableClock
                    calendarClassName={styles.calendar}
                  />
                  <Button
                    icon="geosearch"
                    intent={Intent.NONE}
                    text="Fetch Weather Data"
                    onClick={handleFetchWeather}
                  />
                </div>
                <div className={styles.inputRow}>
                  {(getValues('historical_weather') && !isEmpty(getValues('historical_weather'))) ? (
                    <div className={styles.historicalWeatherContainer}>
                      <span className={styles.historicalWeatherTitle}>Last Fetched Weather Data:</span>
                      <span className={styles.historicalWeatherItem}>Query Date: {format(new Date(get(getValues('historical_weather'), 'asOf', '')), 'MM/dd/yy @ HH:mm:ss')}</span>
                      <span className={styles.historicalWeatherItem}>Temperature: {get(getValues('historical_weather'), 'temperature')}°F</span>
                      <span className={styles.historicalWeatherItem}>Humidity: {get(getValues('historical_weather'), 'humidity')}%</span>
                      <span className={styles.historicalWeatherItem}>Pressure: {get(getValues('historical_weather'), 'pressure')} Hg</span>
                      <span className={styles.historicalWeatherItem}>Wind: {get(getValues('historical_weather'), 'windDirection')}° @ {get(getValues('historical_weather'), 'windSpeed')} mph</span>
                      <span className={styles.historicalWeatherItem}>Wind Scalar: {get(getValues('historical_weather'), 'wind_scalar')}</span>
                      <span className={styles.historicalWeatherItem}>Source: {get(getValues('historical_weather'), 'source')}</span>
                    </div>
                  ) : (
                    <div className={styles.flex}>
                      <NonIdealState
                        title="No Historical Weather"
                        description="Use 'Fetch Weather Data' to fetch historical weather for this environment"
                      />
                    </div>
                  )}
                </div>
                <div className={styles.inputRow}>
                  <FormGroup
                    className={classNames(styles.formGroup, styles.flex)}
                    contentClassName={styles.envInput}
                    label="Temperature [F]"
                    inline
                  >
                    <RHFNumericInput
                      controllerProps={{
                        control,
                        name: 'temperature',
                      }}
                      inputProps={{
                        fill: true,
                        className: styles.inline,
                        value: toString(temperature),
                      }}
                      useStringValue
                    />
                  </FormGroup>
                </div>
                <div className={styles.inputRow}>
                  <FormGroup
                    className={classNames(styles.formGroup, styles.flex)}
                    contentClassName={styles.envInput}
                    label="Humidity [%]"
                    inline
                  >
                    <RHFNumericInput
                      controllerProps={{
                        control,
                        name: 'humidity',
                      }}
                      inputProps={{
                        fill: true,
                        className: styles.inline,
                        value: toString(humidity),
                      }}
                      useStringValue
                    />
                  </FormGroup>
                </div>
                <div className={styles.inputRow}>
                  <FormGroup
                    className={classNames(styles.formGroup, styles.flex)}
                    contentClassName={styles.envInput}
                    label="Pressure [inHg]"
                    inline
                  >
                    <RHFNumericInput
                      controllerProps={{
                        control,
                        name: 'pressure',
                      }}
                      inputProps={{
                        fill: true,
                        className: styles.inline,
                        value: toString(pressure),
                      }}
                      useStringValue
                    />
                  </FormGroup>
                </div>
                <div className={styles.inputRow}>
                  <FormGroup
                    className={classNames(styles.formGroup, styles.flex)}
                    contentClassName={styles.envInput}
                    label="Ambient Wind [deg @ mph]"
                    inline
                  >
                    <div className={styles.gridInputs}>
                      <RHFNumericInput
                        controllerProps={{
                          control,
                          name: 'wind_direction',
                        }}
                        inputProps={{
                          fill: true,
                          value: toString(windDirection),
                        }}
                        useStringValue
                      />
                      <RHFNumericInput
                        controllerProps={{
                          control,
                          name: 'wind_speed',
                        }}
                        inputProps={{
                          fill: true,
                          value: toString(windSpeed),
                        }}
                        useStringValue
                      />
                    </div>
                  </FormGroup>
                </div>
                <div className={styles.inputRow}>
                  <Controller
                    control={control}
                    name="use_lap_wind_data"
                    render={({ field }) => (
                      <Checkbox
                        label="Enable Lap Wind Data"
                        alignIndicator={Alignment.LEFT}
                        checked={field.value || false}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  <FormGroup
                    className={styles.formGroup}
                    contentClassName={styles.envInput}
                    helperText={<ErrorMessage errors={errors} name="lap_wind_data" />}
                    inline
                  >
                    <RHFSelect
                      controllerProps={{
                        control,
                        name: 'lap_wind_data',
                      }}
                      intent={get(errors, 'lap_wind_data') && Intent.DANGER}
                      items={lapWindDataItems}
                      fill
                      disabled={!useLapWindData}
                    />
                  </FormGroup>
                </div>
              </div>
            </Accordion>
            <Accordion
              className={styles.itemContainer}
              id="section-1"
              initialOpen
              key="track"
              title="Track"
              buttonProps={{
                className: styles.accordionHeader,
              }}
            >
              <div className={styles.sectionContainer}>
                <div className={styles.inputRow}>
                  <FormGroup
                    className={classNames(styles.formGroup, styles.flex)}
                    contentClassName={styles.envInput}
                    label="Track"
                    inline
                  >
                    <span>{track}</span>
                  </FormGroup>
                </div>
                <div className={styles.inputRow}>
                  <FormGroup
                    className={classNames(styles.formGroup, styles.flex)}
                    contentClassName={styles.envInput}
                    label="Elevation"
                    inline
                  >
                    <span>{elevation} ft</span>
                  </FormGroup>
                </div>
                {renderTrackImage()}
              </div>
            </Accordion>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
