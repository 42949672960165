import Ajv, { JSONSchemaType } from 'ajv';
import _ from 'lodash';

import { SetupField } from 'graphql/generated/graphql';
import { SetupUITemplate, SetupUITemplateItem, SetupUITemplateItemType } from 'types';

const ajv = new Ajv({ allErrors: true });

/* eslint-disable @typescript-eslint/no-explicit-any */
const schema: JSONSchemaType<SetupUITemplate> = {
  $defs: {
    template_item_array: {
      type: 'array',
      minItems: 1,
      items: {
        anyOf: [
          { $ref: '#/$defs/template_container_item' } as any,
          { $ref: '#/$defs/template_grid_item' } as any,
          { $ref: '#/$defs/template_field_item' } as any,
        ],
      },
    },
    template_container_item: {
      type: 'object',
      properties: {
        type: { type: 'string' },
        name: { type: 'string', pattern: '\\w+' },
        label: { type: 'string' },
        items: { $ref: '#/$defs/template_item_array' },
      },
      required: ['type', 'name', 'items'],
      additionalProperties: false,
    },
    template_grid_item: {
      type: 'object',
      properties: {
        type: { type: 'string' },
        name: { type: 'string', pattern: '\\w+' },
        label: { type: 'string' },
        num_columns: { type: 'integer' },
        num_rows: { type: 'integer' },
        items: { $ref: '#/$defs/template_item_array' },
      },
      required: ['type', 'name', 'items', 'num_columns', 'num_rows'],
      additionalProperties: false,
    },
    template_field_item: {
      type: 'object',
      properties: {
        type: { type: 'string' },
        label: { type: 'string' },
        setup_field: { type: 'string' },
        num_columns: { type: 'integer' },
        column: { type: 'integer' },
        row: { type: 'integer' },
      },
      required: ['type', 'setup_field'],
      additionalProperties: false,
    },
  },
  type: 'object',
  properties: {
    items: { $ref: '#/$defs/template_item_array' } as any,
  },
  required: ['items'],
};
/* eslint-enable @typescript-eslint/no-explicit-any */

export const schemaValidate = ajv.compile(schema);

const getDuplicates = (arr: string[]) => {
  return _.filter(arr, (val, i, iteratee) => _.includes(iteratee, val, i + 1));
};

const getDuplicateSiblingItems = (items: SetupUITemplateItem[]) => {
  const names = items.map(item => {
    return (item.type === SetupUITemplateItemType.CONTAINER || item.type === SetupUITemplateItemType.GRID) ? item.name : item.setup_field;
  });
  return getDuplicates(names);
};

export const customValidate = (template: SetupUITemplate, setupFields: SetupField[]): Error[] | undefined => {
  const setupFieldNames = setupFields.map(f => f.name);
  const templateSetupFields: string[] = [];
  const errors = [];

  const processTemplateItem = (item: SetupUITemplateItem, depth: number) => {
    if (item.type === SetupUITemplateItemType.CONTAINER) {
      if (depth > 3) {
        errors.push(new Error('Maximum container depth exceeded'));
      }
      errors.push(...getDuplicateSiblingItems(item.items).map(d => {
        return new Error(`Item named '${d}' conflicts with a sibling of the same name`);
      }));
      item.items.forEach(i => processTemplateItem(i, depth + 1));
    } else if (item.type === SetupUITemplateItemType.GRID) {
      if (depth > 3) {
        errors.push(new Error('Maximum grid depth exceeded'));
      }
      item.items.forEach(i => processTemplateItem(i, depth + 1));
    } else if (item.type === SetupUITemplateItemType.FIELD) {
      if (!setupFieldNames.includes(item.setup_field)) {
        errors.push(new Error(`Setup field '${item.setup_field}' not a valid setup field`));
      }
      templateSetupFields.push(item.setup_field);
    }
  };

  template.items.forEach(i => processTemplateItem(i, 1));

  errors.push(...getDuplicateSiblingItems(template.items).map(d => {
    return new Error(`Item named '${d}' conflicts with a sibling of the same name`);
  }));
  errors.push(...getDuplicates(templateSetupFields).map(d => {
    return new Error(`Duplicate setup field '${d}' used`);
  }));

  return errors.length > 0 ? errors : undefined;
};
