import { createRef, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  Button,
  Callout,
  ControlGroup,
  EditableText,
  Intent,
} from '@blueprintjs/core';
import { EditorState, RangeSetBuilder, StateEffect, StateField } from '@codemirror/state';
import {
  Decoration,
  DecorationSet,
  EditorView,
  ViewPlugin,
  ViewUpdate,
  hoverTooltip,
} from '@codemirror/view';
import { RegExpCursor } from '@codemirror/search';
import { json } from '@codemirror/lang-json';
import { useCodeMirror } from '@uiw/react-codemirror';
import { eclipse } from '@uiw/codemirror-theme-eclipse';
import { vscodeDark } from '@uiw/codemirror-theme-vscode';
import { classname } from '@uiw/codemirror-extensions-classname';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBracketsCurly } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';

import { specSelectItems } from '../../constants';
import Select from 'components/Select';
import SetupFieldModal from 'components/SetupFieldModal';
import SUITForm from 'components/SUITForm';
import { customValidate, schemaValidate } from 'components/SUITForm/suit-schema';
import defaultSUIT from 'fixtures/default-suit.json';
import {
  Spec,
  SetupField,
  useUpdateSUITMutation,
  UpdateSUITInput,
  useSetupFieldsQuery,
  PartConfig,
  usePartConfigsQuery,
  useSUITByIdQuery,
  SUIT,
} from 'graphql/generated/graphql';
import AppToaster from 'helpers/toaster';
import { selectDarkMode } from 'reducers/ui';
import { SetupUITemplate } from 'types';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import styles from './index.module.css';
import { cloneDeep, set } from 'lodash';

const defaultSUITStr = JSON.stringify(defaultSUIT, null, 2);

/**
 * Setup field name highlighting
 *
 * Creates CodeMirror `StateField`s and `StateEffect`s for updating (similar
 * to a Redux reducer) the setup fields and selected field name and creates a
 * plugin which listens for transactions with that effect (action) in order to
 * highlight all setup fields + the currently selected setup field name
 */
const updateSelectedFieldName = StateEffect.define<string | undefined>();
const updateSetupFields = StateEffect.define<SetupField[]>();
const stateSetupFields: StateField<SetupField[]> = StateField.define({
  create() {
    return [] as SetupField[];
  },
  update(oldValue, tx) {
    let newValue = oldValue;
    tx.effects.forEach(e => {
      if (e.is(updateSetupFields)) newValue = e.value;
    });
    return newValue;
  },
});
const selectedFieldName: StateField<string | undefined> = StateField.define({
  create(): string | undefined {
    return undefined;
  },
  update(oldValue, tx): string | undefined {
    let newValue = oldValue;
    tx.effects.forEach(e => {
      if (e.is(updateSelectedFieldName)) newValue = e.value;
    });
    return newValue;
  },
});
const fieldNameHighlight = Decoration.mark({ class: styles.setupField });
const selectedFieldHighlight = Decoration.mark({ class: styles.selectedSetupField });
const missingFieldHighlight = Decoration.mark({ class: styles.missingSetupField });
const selectedFieldHighlightPlugin = ViewPlugin.fromClass(class {
  decorations: DecorationSet;

  constructor(view: EditorView) {
    this.decorations = this.createDecorations(view);
  }

  update(update: ViewUpdate) {
    this.decorations = this.createDecorations(update.view);
  }

  // eslint-disable-next-line class-methods-use-this
  createDecorations(view: EditorView): DecorationSet {
    const builder = new RangeSetBuilder();

    const highlightValue = view.state.field(selectedFieldName);
    const fields = view.state.field(stateSetupFields);
    if (!highlightValue) return builder.finish() as DecorationSet;

    const { doc } = view.state;
    const cursor = new RegExpCursor(doc, '(?<="setup_field":\\s?").*?(?=")', undefined, 0, doc.length);
    while (!cursor.next().done) {
      const [fieldName] = cursor.value.match;
      const isSelected = fieldName === highlightValue;
      const fieldExists = fields.some(f => f.name === fieldName);

      let highlight = fieldNameHighlight;
      if (isSelected) highlight = selectedFieldHighlight;
      else if (!fieldExists) highlight = missingFieldHighlight;

      builder.add(cursor.value.from, cursor.value.to, highlight);
    }
    return builder.finish() as DecorationSet;
  }
}, { decorations: v => v.decorations });

/**
 * Setup field hover tooltip
 */
export const setupFieldHover = hoverTooltip((view, pos) => {
  const { from, to, text } = view.state.doc.lineAt(pos);
  const matches = text.match(/(?<="setup_field":\s?").*?(?=")/);
  const fieldName = matches?.[0];
  if (fieldName) {
    const fieldNameIndex = text.indexOf(fieldName);
    const start = from + fieldNameIndex;
    const end = to - 1;
    if (pos >= start && pos <= end) {
      return {
        pos,
        end: to,
        above: true,
        create: () => {
          const fields = view.state.field(stateSetupFields);
          const field = fields.find(f => f.name === fieldName);

          const el = document.createElement('div');
          el.className = styles.setupFieldTooltip;

          if (field) {
            const shownField = {
              ...field,
              __typename: undefined,
            };
            if (field.part_config) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              shownField.part_config = { ...field.part_config, __typename: undefined, id: undefined } as any;
            }
            if (field.positions) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              shownField.positions = field.positions.map(p => ({ ...p, __typename: undefined, id: undefined })) as any;
            }
            const setupFieldStr = JSON.stringify(shownField, null, 2);
            new EditorView({ // eslint-disable-line no-new
              parent: el,
              state: EditorState.create({
                doc: setupFieldStr,
                extensions: [vscodeDark, json()],
              }),
            });
          } else {
            el.innerText = 'Setup Field not found';
          }

          return { dom: el };
        },
      };
    }
  }
  return null;
});

const defaultSUITValues = {
  spec: Spec.GEN6,
  name: '',
  description: '',
};

/*
* Component
*/
export default () => {
  const params = useParams();
  const suitId = Number(params.suitId);

  const darkMode = useSelector(selectDarkMode);

  const [suit, setSUIT] = useState<SUIT>();
  const [errors, setErrors] = useState<Error[] | null>(null);
  const [partConfigs, setPartConfigs] = useState<PartConfig[]>([]);
  const [selectedSpec, setSelectedSpec] = useState<Spec | null>(null);
  const [isCreateFieldOpen, setIsCreateFieldOpen] = useState(false);
  const [selectedSetupField, setSelectedSetupField] = useState<SetupField>();
  const [setupFieldStr, setSetupFieldStr] = useState<string>();
  const [setupFields, setSetupFields] = useState<SetupField[]>([]);
  const [templateStr, setTemplateStr] = useState(defaultSUITStr);
  const [template, setTemplate] = useState<SetupUITemplate>(defaultSUIT as unknown as SetupUITemplate);

  useDocumentTitle(
    suit && suit.name ? `Apex Setup - ${suit.name}` : 'Apex Setup'
  );

  const form = useForm<UpdateSUITInput>({ defaultValues: defaultSUITValues });

  useSUITByIdQuery({
    variables: { id: suitId },
    skip: !suitId,
    onCompleted: data => {
      if (data.suit) {
        setSUIT(data.suit);
        form.reset({ ...data.suit });
      }
    },
  });
  usePartConfigsQuery({
    onCompleted: data => setPartConfigs(data.partConfigs.rows as PartConfig[]),
  });
  useSetupFieldsQuery({
    onCompleted: data => setSetupFields(data.setupFields.rows as SetupField[]),
  });
  const [updateSUIT] = useUpdateSUITMutation();

  const filteredSetupFields = useMemo(() => {
    let filteredFields = setupFields as SetupField[];
    if (selectedSpec) {
      filteredFields = filteredFields.filter(field => {
        return field.specs && (field.specs.length === 0 || field.specs.includes(selectedSpec));
      });
    }
    if (filteredFields.length) setSelectedSetupField(filteredFields[0]);
    return filteredFields.map(field => ({ label: field.label, value: field }));
  }, [selectedSpec, setupFields]);

  const setupFieldEditor = createRef<HTMLDivElement>();
  const suitEditor = createRef<HTMLDivElement>();

  const classnameExt = classname({
    add: (lineNumber: number) => {
      // Only need to check the first element here -- if JSON.parse fails, it
      // fails after finding the first error
      if (errors?.[0] instanceof SyntaxError) {
        const res = errors[0].message.match(/line (?<lineNumber>\d+)/);
        if (lineNumber === Number(res?.groups?.lineNumber)) {
          return styles.errorLine;
        }
      }
      return undefined;
    },
  });

  const editorConfig = {
    height: '100%',
    theme: darkMode ? vscodeDark : eclipse,
  };
  const { setContainer: setSetupFieldContainer } = useCodeMirror({
    ...editorConfig,
    container: setupFieldEditor.current,
    extensions: [
      json(),
      stateSetupFields,
    ],
    readOnly: true,
    value: setupFieldStr,
  });
  const { setContainer: setSUITContainer, view } = useCodeMirror({
    ...editorConfig,
    container: suitEditor.current,
    extensions: [
      json(),
      selectedFieldHighlightPlugin,
      selectedFieldName,
      stateSetupFields,
      setupFieldHover,
      classnameExt,
    ],
    onChange: setTemplateStr,
    value: templateStr,
  });

  useEffect(() => {
    if (suit?.template) setTemplateStr(JSON.stringify(suit.template, null, 2));
  }, [suit?.template]);

  useEffect(() => {
    if (setupFieldEditor.current) setSetupFieldContainer(setupFieldEditor.current);
    if (suitEditor.current) setSUITContainer(suitEditor.current);
  }, [setupFieldEditor.current, suitEditor.current]);

  useEffect(() => {
    // Removes the GraphQL `__typename` field for display purposes
    const field = {
      ...selectedSetupField,
      __typename: undefined,
    };
    if (selectedSetupField?.part_config) {
      field.part_config = {
        ...selectedSetupField?.part_config,
        __typename: undefined,
      };
    }
    if (field.positions) {
      field.positions = field.positions.map(p => ({
        ...p,
        __typename: undefined,
      }));
    }
    setSetupFieldStr(JSON.stringify(field, null, 2));

    // Notifies CodeMirror that the selected setup field name has changed
    view?.dispatch(view.state.update({
      effects: [updateSelectedFieldName.of(selectedSetupField?.name)],
    }));
  }, [selectedSetupField, view]);

  useEffect(() => {
    // Notifies CodeMirror that there are new setup fields for highlighting
    view?.dispatch(view.state.update({
      effects: [updateSetupFields.of(setupFields as SetupField[])],
    }));
  }, [setupFields, view]);

  useEffect(() => {
    try {
      if (setupFields.length) {
        const templateObj = JSON.parse(templateStr);
        if (schemaValidate(templateObj)) {
          const customValidationErrors = customValidate(templateObj, setupFields as SetupField[]);
          if (!customValidationErrors) {
            setTemplate(templateObj);
            setErrors(null);
          } else {
            setErrors(customValidationErrors);
          }
        } else {
          setErrors(schemaValidate.errors?.map(e => {
            return new Error(`${e.instancePath ? `${e.instancePath}: ` : ''}${e.message}`);
          }) ?? []);
        }
      }
    } catch (e) {
      setErrors([e as Error]);
    }
  }, [templateStr, setupFields]);

  const onPrettyPrintClicked = () => {
    setTemplateStr(JSON.stringify(JSON.parse(templateStr), null, 2));
  };

  const onSetupFieldCreated = () => {
    setIsCreateFieldOpen(false);
  };

  const handleUpdateSUIT = (input: UpdateSUITInput) => {
    if (!suit) return;
    updateSUIT({
      variables: {
        input: {
          ...input,
          name: suit.name,
          description: suit.description,
          spec: suit.spec,
          template,
        },
      },
      onCompleted: () => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'SUIT successfully updated',
        });
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error updating SUIT: ${e.message}`,
        });
      },
    });
  };

  const containerClasses = classNames('suitForm', styles.container, { [styles.dark]: darkMode });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSUITMetaChange = (target: string, value: any) => {
    if (!suit) return;

    const newSUIT = cloneDeep(suit);
    set(newSUIT, target, value);
    setSUIT(newSUIT);
  };

  const isDesignerMode = true;
  return (
    <>
      {!errors && (
        <Callout
          className={classNames(styles.calloutContainer, styles.successContainer)}
          icon={false}
          intent={Intent.SUCCESS}
          title="Template is valid and can be saved"
        >
          <Button
            icon="add"
            onClick={form.handleSubmit(handleUpdateSUIT)}
            text="Save SUIT"
          />
        </Callout>
      )}
      {errors && (
        <Callout
          className={classNames(styles.calloutContainer, styles.errorContainer)}
          intent={Intent.DANGER}
          title="Template Invalid"
        >
          <ul className={styles.errorsList}>
            {errors.slice(-5).map((e, i) => <li key={`error-${i}`}>{e.message}</li>)}
          </ul>
          {errors.length > 5 && (
            <p>...and {errors.length - 5} more errors</p>
          )}
        </Callout>
      )}
      <div className={styles.titleBar}>
        <div className={styles.titleContainer}>
          <div className={styles.titleColumn}>
            <div className={styles.titleLabel}>Spec</div>
            <Select
              buttonProps={{ className: styles.specSelectButton }}
              initialItem={specSelectItems.find(i => suit?.spec === i.value)}
              items={specSelectItems}
              noSelectionText="Spec"
              onChange={item => onSUITMetaChange('spec', item.value)}
            />
          </div>
          <div className={styles.titleColumn}>
            <div className={styles.titleLabel}>Name</div>
            <EditableText
              className={styles.titleValue}
              value={suit?.name}
              onChange={value => onSUITMetaChange('name', value)}
              placeholder="Name"
            />
          </div>
          <div className={styles.titleColumnDesc}>
            <div className={styles.titleLabel}>Description</div>
            <EditableText
              className={styles.titleValue}
              value={suit?.description || ''}
              onChange={value => onSUITMetaChange('description', value)}
              placeholder="Description"
            />
          </div>
        </div>
      </div>
      <div className={containerClasses}>
        <div className={styles.editorColumn}>
          <div className={styles.setupSelectContainer}>
            <ControlGroup>
              <Select
                buttonProps={{ className: styles.specSelectButton }}
                items={specSelectItems}
                noSelectionText="Spec"
                onChange={spec => setSelectedSpec(spec.value)}
              />
              <Select
                buttonProps={{ className: styles.setupFieldSelectButton }}
                fill
                value={selectedSetupField ? { label: selectedSetupField.label, value: selectedSetupField } : undefined}
                items={filteredSetupFields}
                noSelectionText="Select Setup Field"
                onChange={item => setSelectedSetupField(item.value)}
                popoverProps={{ fill: true }}
              />
              <Button
                icon="add"
                onClick={() => setIsCreateFieldOpen(true)}
                title="Add Setup Field"
              />
            </ControlGroup>
          </div>
          <div className={styles.setupFieldEditor}>
            <span className={classNames(styles.editorLabel, 'bp4-text-small')}>Setup Field</span>
            <div className={styles.editorContainer} id="setupFieldEditor" ref={setupFieldEditor} />
          </div>
          <div className={styles.suitEditor}>
            <span className={classNames(styles.editorLabel, 'bp4-text-small')}>SUIT</span>
            <Button
              className={styles.prettyPrintButton}
              icon={<FontAwesomeIcon icon={faBracketsCurly} />}
              minimal
              onClick={onPrettyPrintClicked}
              outlined
              title="Auto-format SUIT"
            />
            <div className={styles.editorContainer} id="suitEditor" ref={suitEditor} />
          </div>
        </div>
        <div className={styles.previewContainer}>
          <SUITForm
            template={template}
            setupFields={setupFields as SetupField[]}
            isDesignerMode={isDesignerMode}
          />
        </div>
      </div>
      <SetupFieldModal
        isOpen={isCreateFieldOpen}
        onClose={() => setIsCreateFieldOpen(false)}
        onSuccess={onSetupFieldCreated}
        partConfigs={partConfigs}
      />
    </>
  );
};
