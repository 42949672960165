import { useEffect, useCallback, useState } from 'react';
import {
  Button,
  FormGroup,
  Intent,
  Checkbox,
  Alignment,
  EditableText,
  Tree,
  TreeNodeInfo,
  NumericInput,
} from '@blueprintjs/core';
import { useForm, useWatch, Controller } from 'react-hook-form';
import classNames from 'classnames';
import {
  useBlocker,      // eslint-disable-line camelcase
  BlockerFunction, // eslint-disable-line camelcase
} from 'react-router-dom';

import {
  Sim,
  useUpdateSimMutation,
  UpdateSimInput,
} from 'graphql/generated/graphql';
import Accordion from 'components/Accordion';
import RHFNumericInput from 'components/RHFInputs/NumericInput';
import RHFTextInput from 'components/RHFInputs/TextInput';
import styles from '../index.module.css';
import AppToaster from 'helpers/toaster';
import { addWarningListener, removeWarningListener } from 'helpers/browserCloseWarning';
import { KinematicsSim } from '../types';

interface Props {
  sim: Sim;
}

const nodes: TreeNodeInfo[] = [
  {
    id: 0,
    hasCaret: false,
    label: 'Config',
  },
  {
    id: 1,
    hasCaret: false,
    label: 'Output',
  },
];

export default (props: Props) => {
  const defaultKinematicsSim: KinematicsSim = props.sim;
  const [isSaving, setIsSaving] = useState(false);
  const form = useForm<Partial<KinematicsSim>>({
    defaultValues: {
      ...defaultKinematicsSim,
    },
  });
  const { control, setValue, formState: { isDirty } } = form;

  const [updateSim] = useUpdateSimMutation();

  const rollAngle = useWatch({
    control,
    name: 'data.roll_angle',
  });

  const steerWheelAngle = useWatch({
    control,
    name: 'data.steer_wheel_angle',
  });

  useEffect(() => {
    if (isDirty) {
      addWarningListener();
    } else {
      removeWarningListener();
    }
  }, [isDirty]);

  // Removes the listener when deconstructing this component
  useEffect(() => removeWarningListener, []);

  // eslint-disable-next-line
  const blockerFunc: BlockerFunction = () => {
    if (isDirty) {
      // eslint-disable-next-line
      return !window.confirm(
        'There are unsaved changes. Navigate away from this view?'
      );
    }
    return false;
  };
  useBlocker(blockerFunc);

  const handleNodeClick = useCallback(
    (node: TreeNodeInfo) => {
      const sectionElement = document.getElementById(`section-${node.id}`);

      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' });
      }
    },
    [],
  );

  const onSetupNameChange = (value: string) => {
    setValue('name', value, { shouldDirty: true });
  };

  const onSubmit = (input: Partial<KinematicsSim>) => {
    const handleSubmit = async () => {
      setIsSaving(true);

      try {
        // eslint-disable-next-line camelcase, @typescript-eslint/no-unused-vars
        const { drive_file, sim_states, driver, lap_time_data, eight_post, ...kinematicsSim } = input;

        const updatedSimInput: UpdateSimInput = {
          ...kinematicsSim as KinematicsSim,
        };

        const result = await updateSim({
          variables: {
            input: {
              ...updatedSimInput,
            },
          },
        });

        if (result.data) {
          AppToaster.show({
            intent: Intent.SUCCESS,
            message: 'Sim successfully updated',
          });

          form.reset({ ...input });
        } else {
          throw new Error('Update failed');
        }
      } catch (error) {
        let errorMessage = 'An unknown error occurred';
        if (error instanceof Error) {
          errorMessage = error.message;
        } else if (typeof error === 'string') {
          errorMessage = error;
        }
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error updating Sim: ${errorMessage}`,
        });
      } finally {
        setIsSaving(false);
      }
    };

    handleSubmit();
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <div className={styles.titleBar}>
        <div className={styles.titleLabel}>Kinematics</div>
        <EditableText
          className={styles.titleValue}
          defaultValue={props.sim.name}
          onChange={value => onSetupNameChange(value)}
          placeholder="Kinematics"
        />
      </div>
      <div className={styles.mainContainer}>
        <div className={styles.nav}>
          <Button
            className={styles.saveButton}
            intent={Intent.PRIMARY}
            type="submit"
            text="Save"
            fill
            disabled={!isDirty || isSaving}
          />
          <div className={styles.sideNavContainer}>
            <Tree
              contents={nodes}
              onNodeClick={handleNodeClick}
            />
          </div>
        </div>
        <div className={styles.simContainer}>
          <Accordion
            className={styles.itemContainer}
            id="section-0"
            initialOpen
            key="config"
            title="Config"
            buttonProps={{
              className: styles.accordionHeader,
            }}
          >
            <div className={styles.sectionContainer}>
              <div className={styles.inputRow}>
                <FormGroup
                  className={classNames(styles.formGroup, styles.flex)}
                  contentClassName={styles.simInput}
                  label="Max Wheel Travel"
                  inline
                >
                  <RHFNumericInput
                    controllerProps={{
                      control,
                      name: 'data.max_wheel_travel',
                    }}
                    inputProps={{
                      fill: true,
                    }}
                  />
                </FormGroup>
              </div>
              <div className={styles.inputRow}>
                <FormGroup
                  className={classNames(styles.formGroup, styles.flex)}
                  contentClassName={styles.simInput}
                  label="Roll Angle"
                  inline
                >
                  <div className={styles.gridInputs}>
                    <NumericInput
                      name="negative_roll_angle"
                      disabled
                      fill
                      value={(rollAngle * -1)}
                      buttonPosition="none"
                    />
                    <RHFNumericInput
                      controllerProps={{
                        control,
                        name: 'data.roll_angle',
                      }}
                      inputProps={{
                        fill: true,
                      }}
                    />
                  </div>
                </FormGroup>
              </div>
              <div className={styles.inputRow}>
                <FormGroup
                  className={classNames(styles.formGroup, styles.flex)}
                  contentClassName={styles.simInput}
                  label="Steer Wheel Angle"
                  inline
                >
                  <div className={styles.gridInputs}>
                    <NumericInput
                      name="negative_steer_wheel_angle"
                      disabled
                      fill
                      value={(steerWheelAngle * -1)}
                      buttonPosition="none"
                    />
                    <RHFNumericInput
                      controllerProps={{
                        control,
                        name: 'data.steer_wheel_angle',
                      }}
                      inputProps={{
                        fill: true,
                      }}
                    />
                  </div>
                </FormGroup>
              </div>
            </div>
          </Accordion>
          <Accordion
            className={styles.itemContainer}
            id="section-1"
            initialOpen
            key="output"
            title="Output"
            buttonProps={{
              className: styles.accordionHeader,
            }}
          >
            <div className={styles.sectionContainer}>
              <div className={styles.inputRow}>
                <FormGroup
                  className={classNames(styles.formGroup, styles.flex)}
                  contentClassName={styles.simInput}
                  label="Output Directory"
                  inline
                >
                  <RHFTextInput
                    controllerProps={{
                      control,
                      name: 'output_directory',
                    }}
                    inputProps={{
                      fill: true,
                    }}
                  />
                </FormGroup>
              </div>
              <div className={styles.inputRow}>
                <div className={styles.outputCheckboxContainer}>
                  <div className={styles.outputCheckboxName}>Motec</div>
                  <Controller
                    control={control}
                    name="export_motec"
                    render={({ field }) => (
                      <Checkbox
                        label="Export"
                        alignIndicator={Alignment.LEFT}
                        checked={field.value || false}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="download_motec"
                    render={({ field }) => (
                      <Checkbox
                        label="Download"
                        alignIndicator={Alignment.LEFT}
                        checked={field.value || false}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </div>
              </div>
              <div className={styles.inputRow}>
                <div className={styles.outputCheckboxContainer}>
                  <div className={styles.outputCheckboxName}>JSON</div>
                  <Controller
                    control={control}
                    name="export_json"
                    render={({ field }) => (
                      <Checkbox
                        label="Export"
                        alignIndicator={Alignment.LEFT}
                        checked={field.value || false}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="download_json"
                    render={({ field }) => (
                      <Checkbox
                        label="Download"
                        alignIndicator={Alignment.LEFT}
                        checked={field.value || false}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </Accordion>
        </div>
      </div>
    </form>
  );
};
