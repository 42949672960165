import React, { useState, useEffect } from 'react';
import styles from './index.module.css';

interface GridProps {
  label?: string;
  numColumns: number;
  numRows: number;
  children: React.ReactNode[];
  id: string;
  isDesignerMode: boolean;
}

const SUITGrid: React.FC<GridProps> = ({ numColumns, numRows, children, label, id, isDesignerMode  }) => {
  const [gridStructure, setGridStructure] = useState<Array<Array<React.ReactNode>>>([]);

  useEffect(() => {
    if (numRows > 0 && numColumns > 0) {
      const initGrid = Array(numRows).fill(0).map(() => Array(numColumns).fill(<div className={styles.gridItemPlaceholder} />));
      setGridStructure(initGrid);
    }
  }, [numRows, numColumns]);

  const getCellContent = (gridRow: number, gridColumn: number, defaultContent: React.ReactNode) => {
    if (!children || gridStructure.length === 0 || gridStructure[0].length === 0) return defaultContent;

    let foundChild: React.ReactNode | null = null;

    children.forEach((child) => {
      if (React.isValidElement(child)) {
        const [rowPart, columnPart] = child.key ? child.key.split('-') : ['0', '0'];
        const row = Number(rowPart) || 0;
        const column = Number(columnPart) || 0;

        if (row >= 0 && row < gridStructure.length && column >= 0 && column < gridStructure[0].length && row === gridRow && column === gridColumn) {
          foundChild = child;
        }
      }
    });

    return foundChild || defaultContent;
  };

  const renderGrid = () => {
    if (gridStructure.length === 0) {
      return null;
    }

    return (
      <div key={id} className={styles.suitGridContainer}>
        {label && <div key={`${id}label`} className={styles.gridLabel}>{label}</div>}
        <div key={`${id}suitGrid`} className={styles.suitGrid}>
          {gridStructure.map((row, rowIndex) => (
            <div key={`${rowIndex}gridRow`} className={styles.gridRow}>
              {row.map((childItem, columnIndex) => (
                <div
                  key={`${rowIndex}-${columnIndex}`}
                  className={isDesignerMode ? styles.gridItemDesigner : styles.gridItem}
                >
                  {getCellContent(rowIndex, columnIndex, childItem)}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      {renderGrid()}
    </>
  );
};

export default SUITGrid;
